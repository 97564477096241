<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.main {
  min-height: 100vh;
  height: 100%;
  background: #fafafa;
}

.container {
  height: 100%;
  padding: 40px 20px !important;
}

.container > .main-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 8px;
}

.header {
  height: 50px;
  padding: 20px;
  background: #e55d87;
  background: -webkit-linear-gradient(
    160deg,
    rgb(3, 69, 211) 0%,
    rgb(15, 162, 207) 100%

  ); 
  background: linear-gradient(
    160deg,
    rgb(3, 69, 211) 0%,
    rgb(15, 162, 207) 100%
  );

  border-radius: 8px;
  margin-bottom: 25px;

  display: flex;
  align-items: center;
}

.header > .text-header {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}

</style>
