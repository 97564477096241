import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/paineis-bi',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/sistemas',
    component: () => import('../views/Sistemas.vue'),
    children: [
      {
        path: '',
        name: 'Catalogo',
        component: () => import('../components/sistemas/AllSystems-comp.vue')
      },
      {
        path: '/sistemas/assistencias',
        name: 'Assistenciais',
        component: () => import('../components/sistemas/Assistance-comp.vue')
      },
      {
        path: '/sistemas/administrativos',
        name: 'Administrativos',
        component: () => import('../components/sistemas/Administrative-comp.vue')
      },
    ]
  },
  {
    path: '/aplicacao/usuarios',
    component: () => import('../views/Sistemas.vue'),
    children: [
      {
        path: '',
        name: 'Usuarios',
        component: () => import('../views/Usuarios.vue')
      },
      {
        path: '/aplicacao/dashboards',
        name: 'NovoDashboard',
        component: () => import('../components/crud/NewDashboard-comp.vue')
      },
      {
        path: '/aplicacao/sistemas',
        name: 'NovoSistema',
        component: () => import('../components/crud/NewSystem-comp.vue')
      },
    ]
  },
  {
    path: '/minha-conta',
    name: 'MinhaConta',
    component: () => import('../views/Conta.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
